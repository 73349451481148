.signin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: relative;
}

.signin-container>.signin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 0.5px solid lightgrey;
    width: 100%;
    max-width: 350px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: lightgrey;
    margin-top: 10px;
}

.message-form {
    position: absolute;
    z-index: 100;
    top: 0px;
}

.message-form>div {
font-size: 14px;
margin-top: 20px;
}
.signin_portal {
    width: 90%;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: -15px;
}

.signin>.signin_portal>img {
    object-fit: contain;
    height: 50px;
    margin-right: 20px;
    border-radius: 50px;
}

.signin>.signin_portal>p,
.signin>form>h2,
.signin>form>h3,
.signin>form>.signin_link {
    color: #003366;
    font-family: Helvetica, sans-serif, Arial;
}

.signin_link > a{
    cursor: pointer;
    color: #7e0556 !important;
}

.signin>form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signin>form>h2 {
    margin-bottom: 5px;
    margin-top: 0;
}

.signin>form>input {
    width: 100%;
    height: 25px;
    font-size: 20px;
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 5px;
    margin-bottom: 10px;
    outline: none;
    border: none;
    border-bottom: 1px dotted#003366;
    color: #003366;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-family: Helvetica, sans-serif, Arial;
}

.signin>form>button {
    width: 100%;
    box-sizing: border-box;
    padding: 5px 0;
    margin-top: 10px;
    outline: none;
    border: 1px solid #999;
    background-color: #6993e9;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    transition: all 0.3s linear 0s;
    margin-bottom: 15px;
}

.signin>form>button:hover {
    background-color: #003366;
    opacity: 0.7;
}

.signin>form>.signin_link {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none;
}

.signin>.resettimer {
    display: flex;
    flex-direction: column;
}

.resettimer>.timer {
    margin-left: 40px;
}

.signin>.resettimer>button {
    padding: 10px;
    margin-top: 10px;
    outline: none;
    border: 1px solid #999;
    background-color: #6993e9;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    transition: all 0.3s linear 0s;
}

.signin>.resettimer>button:hover {
    background: #003366;
    opacity: 0.7;
}

.signin>form>button:active,
.signin>.resettimer>button:active {
    box-shadow: none;
}

.timer {
    color: #003366;
    font-family: Helvetica, sans-serif, Arial;
}

.signin>.resettimer>button[disabled],
.signin>form>button[disabled] {
    background-color: grey !important;
}