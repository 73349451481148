.landing {
    background-color: #8d93b8;
    margin: 0;
    padding: 0;
    margin: auto
}

@media (max-width: 480px){
    .landing .navbar-fixed-top{
        width: 95% !important;
    }
}
.landing .li-nav,
.landing .navbar-brand{
    color: black;
}

.landing .benefit>h2,
.landing .benefit>h1{
    font-weight: bold;
}
.landing .benefit>h2>span{
    color: White;
    font-weight:bold;
}
.landing .benefit>h2>span>a{
    color: blue;
}

.landing .benefit>h3>a>span{
    color: brown;
}
.landing #pricing>div>h3{
    font-weight: bold;
}
.landing .com{
    height: 70px;
}
.landing .com>h2{
    font-weight: bold;
}
.landing .free-sms{
    font-size:18px;
    color: white;
}
.landing .free-sms>div{
    font-size:22px;
    color: brown;
    text-align: center;
}
.landing #contacts{
     border-radius:15px 
}

.landing #contacts>p>a>i{
    font-size:14px; 
}
.landing #contacts>img{
    width: 50px;
    border-radius: 10%;
}

.landing ul {
    list-style: none;
}

.landing nav ul li a {
    padding-right: 30px;
    font-weight: bold;
    color: white;
    text-decoration: none;
}

.landing nav ul li a:hover {
    color: purple;
    transition: all 0.5s ease-in;
}

.landing .carousel-inner {
    width: 100%;
    padding-top: 5px;
}

.landing .carousel-inner img {
    width: 100%;
}

.landing .item {
    width: 100%;
}

.landing .benefit {
    color: white;
    padding: 20px;
    padding-top: 56px;
    text-align: center;
    font-weight: bold;
}

.landing .com {
    text-align: center;
    font-weight: bold;
    color: white;
    padding-bottom: 10px;
}

.landing .solution {
    padding-bottom: 20px;
}

.landing .sol {
    background: rgba(209, 209, 209, 0.8);
    text-align: center;
    padding: 10px;
    margin: 10px;
}

.landing .pricing {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    text-align: center;
    list-style: none;
    font-weight: bold;
    width: 100%;
    color: white;
}

.landing table {
    width: 100%;
    height: 100%;
}

.landing .contacts {
    margin-top: 40px;
    text-align: center;
    list-style: none;
    padding: 60px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
}

.landing th,
.landing td {
    padding: 7px 10px 10px 10px;
}

.landing th {
    text-transform: uppercase;
    letter-spacing: 0.3em;
    font-size: 15px;
    border: 3px solid #999;
    font-weight: bold;
    text-align: center;
    font-weight: bold;
    color: white;
    background-color: darkgrey;
}

.landing td {
    border: 3px solid #999;
}

.landing tr.even {
    background-color: #077e38;
}

.landing tr:hover {
    background-color: hsl(178, 41%, 83%);
    font-weight: bold;
}
.landing .panel-head-icon{
    text-align: center;
     font-size:48px;
     color:brown;
}
.landing .panel-body{
    height: 150px;
}


.landing h2 a span{
    color: #337ab7;
    text-decoration: none
}

.landing #contacts > p:nth-child(8) > a > span{
    margin-left: 5px;
}

.landing .company-name{
    display: flex;
}

.landing .company-name>img{
    width: 40px;
    height: 30px;
    margin-top: -2px;
    border-radius: 50%;
}


@media screen  and (max-width: 378px){

    .landing .free-container{
        margin-bottom: 50px;
    }
    .landing .free-sms{
        margin-left: auto;
        margin-right: auto;
    }
    .landing th {
        letter-spacing: 0.0em;
    }
    .landing .panel {
        height: 230px;
    }
}