.app {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: 400;
    color: #212b36;
    line-height: 1.5;
    background-color: lightgrey;
    height: 100vh;
    overflow: hidden;
}

header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    align-items: center;
    margin: 0 10px 0 10px;
  
    position: sticky;
   
}

header>.creditexpires,
header>.creditbalance,
header>div>.creditbalance,
header>.senderid,
header>.companyfield {
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 20px hsl(210 14% 90%);
}

header>.contact {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 20px hsl(210 14% 90%);
}

header>.profile {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 20px hsl(210 14% 90%);
}
header>.profile>button{
    font-size: 12px;
}
#demo-popup-menu > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper.css-pwxzbm > ul > li{
    font-size: 12px;
}
header>.senderid>a>.text {
    color: black;
}

header>.companyfield {
    width: 200px;
}
header>.companyfield>span {
    font-size: 10px;
    color: black;
}

header>.companyfield>.companytitle {
    display: flex;
}
header>.companyfield>.companytitle>img {
    width: 20px;
    border-radius: 50%;
    margin-right: 2px;
}

  
.home-links{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    color: #003366;
  font-style: italic;
}
.toggle_bar>span{
    height: 1px; 
    background: blue;
}
form>h2 {
    align-self: center;
}

.contact>.text {
    margin-left: 5px !important;
}

.section {
    display: flex;
   
}

.section-sidebar {
    max-width: 200px;
    padding: 0 10px;
    margin: 20px 20px auto 10px;
    box-shadow: 0 0 10px hsl(210 14% 90%);
    width: 25%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.section-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    flex: 1;
    height: 120vh;
}

.message-app {
    position: absolute;
    z-index: 100;
    margin: 2px 10%;
    top: 0%
}

.message-app>div {
    font-size: 14px;
    margin-top: 20px;
}

.recurringsms.screentabs,
.scheduledsms.screentabs,
.phonebook.screentabs,
.credithistory.screentabs,
.dashboard.screentabs {
    margin: 10px 0px;
}

.scheduled,
.sentSms {
    background-color: white;
    border-radius: 10px;
    margin: 20px;
    box-shadow: 0px 10px 13px -7px #000000;
    display: flex;
    flex-direction: column;
    color: #003366;
    font-family: Helvetica, sans-serif, Arial;
    padding: 20px;
}

.scheduled-message,
.sentSms-message {
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    margin: 10px;
    margin-bottom: 5px;
    cursor: text;
        padding-top: 10px;
    padding-bottom: 10px;
}

.scheduled-flex,
.sentSms-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.scheduled-details,
.sentSms-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.scheduled-details>div,
.sentSms-details>div {
    color: black;
}

.scheduled-details>div>span,
.sentSms-details>div>span {
    font-weight: bold;
    font-size: 10px;
}

.recurringsms,
.scheduledsms,
.dashboard {
    width: 100%;
}

.smsview {
    height: 78vh;
    overflow: hidden;
}

.search {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.section-main>.app-form {
    border-radius: 10px;
    margin-top: 10px;
    color: #003366;
    font-family: Helvetica, sans-serif, Arial;
    height: 120vh;
    width: 90%;
    padding: 5px 5% 5px 5%;
    margin-bottom: 220px;
    overflow-y: auto;
}

.admin{
    width: 90%;
    overflow-y: auto;
    height: 120vh;
    padding: 5px 5% 5px 5%;
    margin-bottom: 200px;
    font-family: Helvetica, sans-serif, Arial;
    height: 120vh;
    overflow-y: auto;
}
.searchuser{
    margin-top: 40px;
    margin-left: 40%;
    margin-bottom: 5px;
}
.section-main>.app-form>form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.credit-container{
     display: flex;
 }

.credit-container>img{
    cursor:pointer;
     width:15px;
     object-fit: contain;
     border-radius: 5px;
 }


.toggle_bar{
   cursor:pointer;
    width: 30px; 
}
.toggle_bar>img{
    width: 30px;
    object-fit: contain;
    border-radius: 5px;
}
.modal>form>label,
.app-form>form>label {
    font-weight: bold;
    margin-bottom: 5px;
    align-self: flex-start;
    margin-left: 5%;
    border-bottom: solid 0.5px;
    margin-top: 10px;
}

.radio-buttons {
    display: flex;
    justify-content: center;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5%;
    margin-right: 5%;
    outline: none;
    border: 1px #003366;
    color: #003366;
    border-radius: 5px;
    font-family: Helvetica, sans-serif, Arial;
    background: #eff4f3;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
}

@media (max-width: 1200px){
    .radio-buttons {
        justify-content: flex-start; 
    }
}

@media (max-width: 480px){
    .radio-buttons {
        flex-direction: column;
        align-items: flex-start;
    }
    .link-senderid>button{
        font-size: 7px !important;
    }
}

.radio-buttons>div {
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.radio-buttons>div>span {
    margin-left: 5px;
}

.app-form>form>textarea {
    margin: 0 5%;
    min-height: 60px;
    padding-left: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
    border: 1px #003366;
    color: #003366;
    border-radius: 5px;
    font-family: Helvetica, sans-serif, Arial;
    background: #eff4f3;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.phonebook,
.excel {
    display: flex;
    flex-direction: column;
    margin: 5px 5% 8px 5%;
    height: 120vh;
    width: 90%;
    padding: 5px 5% 5px 5%;
    margin-bottom: 200px;
    font-family: Helvetica, sans-serif, Arial;
    height: 120vh;
    overflow-y: auto;
}

.excel>img {
    width: 40%;
    margin-bottom: 2px;
    align-self: center;
}

.phonebook>img {
    object-fit: contain;
    width: 85%;
    margin-bottom: 2px;
    align-self: center;
}

.phonebook>span {
    align-self: center;
}

.app-form>form>.datetimepicker {
    margin: 0 5%;
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
    border: 1px #003366;
    color: #003366 !important;
    border-radius: 5px;
    font-family: Helvetica, sans-serif, Arial;
    background: #eff4f3;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.app-form>form>select,
.app-form>form>input[type=date],
.app-form>form>input[type=text] {
    margin: 0 5%;
    padding-left: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
    border: 1px #003366;
    color: #003366;
    border-radius: 5px;
    font-family: Helvetica, sans-serif, Arial;
    background: #eff4f3;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.recipients {
    align-self: flex-end;
    margin-right: 5%;
}

.message-count {
    margin: 5px 5% 5px 5%;
    display: flex;
    justify-content: space-between;
}
.background-image-upload,
.logo-upload,
.excel-mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
}
.background-image-upload,
.logo-upload{
    margin-left: -5%;
}

.timezone {
    margin: 5px 5% 10px 5%;
}

.app-form>form>button {
    width: 50%;
    align-self: center;
    box-sizing: border-box;
    padding: 5px 0;
    margin: 15px 15px 25px 15px;
    outline: none;
    border: 1px solid #999;
    background-color: #6993e9;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    transition: all 0.3s linear 0s;
}

.app-form>form>button:hover {
    background-color: #003366;
    opacity: 0.7;
}

.app-form>form>button:active {
    box-shadow: none;
}

.app-form>form>button[disabled] {
    background-color: grey !important;
}

.recurringspanmessge {
    margin: 10px 5% 3px 5%;
}

.recurringspan {
    margin: 0 5%;
    align-self: flex-end;
}

.table1>table> {
    font-size: 50px;
    width: 100%;
}

.table1>table>thead{
    background-color: honeydew;
}
.table1>table>tr {
    background-color: honeydew;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    overflow: auto;
    width: inherit;
    max-width: 350px;
    margin: auto;
    margin-top: 8% !important;
}

.modal>form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 0.5px solid lightgrey;
    width: 100%;
    max-width: 350px;
    padding: 20px;
    background-color: lightgrey;
    margin: auto;
}
.excel1{
    display: flex;
    flex-direction: column;
    padding: 10px 5% 10px 5%;
}

#root > div > div > div.section > div.section-main > div.app-form > form > div.excel1 > img{
    margin-bottom: 10px;
    width: 80%;
    align-self: center;
}

#excel-phone{
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    overflow: auto;
    width: inherit;
    margin: auto;
    margin-top: 8%;
}

#excel-phone>form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 0.5px solid lightgrey;
    width: 100%;
    /*max-width: 350px;*/
    padding: 20px;
    background-color: lightgrey;
    margin: auto;
}
#excel-phone > form > input[type=file]{
    margin-bottom: 10px;
}

.modal>form>select,
.modal>form>input[type=date],
.modal>form>input[type=text] {
    margin: 0 5%;
    padding-left: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
    border: 1px #003366;
    color: #003366;
    border-radius: 5px;
    font-family: Helvetica, sans-serif, Arial;
    background: #eff4f3;
    width: 100%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.modal>form>.datetimepicker {
    margin: 0 5%;
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
    border: 1px #003366;
    color: #003366 !important;
    border-radius: 5px;
    font-family: Helvetica, sans-serif, Arial;
    background: #eff4f3;
    width: 100%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.modal>form>textarea {
    margin: 0 5%;
    min-height: 60px;
    padding-left: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
    border: 1px #003366;
    color: #003366;
    border-radius: 5px;
    font-family: Helvetica, sans-serif, Arial;
    background: #eff4f3;
    width: 100%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.modal>form>button {
    margin: 10px;
}

.modal>form>button:hover {
    background-color: #003366;
    opacity: 0.7;
}

.createcontact>form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
}

.createcontact>form>button {
    margin-top: 5px;
}

.createcontact>form>input {
    width: 25%;
    height: 25px;
    font-size: 20px;
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    outline: none;
    border: none;
    border-bottom: 1px dotted #003366;
    color: black;
    border-radius: 5px;
    background: rgb(208, 220, 245);
    font-size: 14px;
    font-family: Helvetica, sans-serif, Arial;
}

.createcontact>form>.datetimepicker {
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
    color: #003366 !important;
    width: 25%;
    border-radius: 5px;
    border-color: none;
    font-family: Helvetica, sans-serif, Arial;
    background: rgb(208, 220, 245);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.createcontact>form>label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #011d3a;
    font-size: 18px;
}
.link-senderid>button{
    width: 210px;
    align-self: center;
    box-sizing: border-box;
    padding: 5px 5px;
    outline: none;
    border: 1px solid #999;
    background-color: #f56f47;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    transition: all 0.3s linear 0s;
    font-size: 10px;
}
.back>button {
    margin: 10px 0;
    width: 100px;
    align-self: center;
    box-sizing: border-box;
    padding: 5px 5px;
    outline: none;
    border: 1px solid #999;
    background-color: #6993e9;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    transition: all 0.3s linear 0s
}

.dashboard-details {
  
    margin: 20px auto;
    font-size: 18px;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 75%);
    width: 300px;
}

.table {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 10px;
    width: 100%;
}

.table1 {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 10px;

}

.scheduled-icon {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.delete {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 200px;
}

.scheduled-icon>svg[data-testid=DeleteIcon] {
    color: rgb(235, 91, 91);
    margin-left: 5px;
    cursor: pointer;
}

.scheduled-icon>svg[data-testid=DeleteIcon]:hover {
    color: rgb(242, 37, 37);
}

.edit-icon>svg[data-testid=EditIcon],
.scheduled-icon>svg[data-testid=EditIcon] {
    color: rgb(142, 106, 240);
    cursor: pointer;
}

.edit-icon>svg[data-testid=EditIcon]:hover,
.scheduled-icon>svg[data-testid=EditIcon]:hover {
    color: rgb(92, 38, 240);
}

.createpb {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.profile-screen {
    margin-top: 5px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y:scroll;
    margin-bottom: 200px;
}
.profile-screen button{
    font-size: 12px;
}
.accountdetails{
    margin-bottom: 15px;
    font-size: 20px;
    background: white;
    width: 90%;
padding: 20px;
border-radius: 10px;
/* display: flex; */
font-weight: bold;
}



.profile-copy {
    display: flex;
    position: relative;
  
}
.profile-copy > div > div{
    position: absolute;
    top:-40px;
    color: rgb(6, 65, 6);
}
.profile-screen > div:nth-child(4){
    margin-bottom: 20px
}
.profile-screen > div:nth-child(6){
    margin-top: 20px;
    margin-bottom: 20px
}

.admin{
    width: 90%;
}
.pendingsenderid{
display: flex;
justify-content: space-between;
background: white;
padding: 10px;
border-radius: 10px;

margin-bottom: 10px;
margin-top: 10px;
}
.approve{
 
    box-sizing: border-box;
    padding: 5px 10px;
    margin-top: 10px;
    outline: none;
    border: 1px solid #999;
    background-color:#458549;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    transition: all 0.3s linear 0s;

}
.approve:hover {
    background-color: rgb(3, 75, 3);
    opacity: 0.7;
}

.approve:active {
    background-color: rgb(3, 141, 3);
}

.reject{
    box-sizing: border-box;
    padding: 5px 10px;
    margin-top: 10px;
    outline: none;
    border: 1px solid #999;
    background-color:#b65308;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    transition: all 0.3s linear 0s;

}

.reject:hover {
    background-color: rgb(106, 25, 6);
    opacity: 0.7;
}

.reject:active {
    background-color: rgb(141, 40, 3);
}
.profile-screen>.profile-copy>input[type=text] {
    padding-top: 8px;
    padding-bottom: 6px;
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
    border: 0;
    background: #eff4f3;
    font-family: Helvetica, sans-serif, Arial;
   
    width: 100%;
}
.profile-screen>.profile-copy{
    display: flex;
    background: #eff4f3;
  height: inherit;
    /*color: #003366;*/
    color: black;
    border-radius: 5px;
    width: 60%;
    border-radius: 8px;
    position: relative;
    margin-top: 10px;
    margin-bottom: 100px;

}
.profile-screen>.profile-copy>.MuiSvgIcon-root{
    background-color:  #eff4f3;
    padding: 6px 6px 6px 6px;
    border-radius: 10px;
}
.profile-screen>.profile-copy>.MuiSvgIcon-root:hover{
    background-color: #ddd;
 
    opacity: 0.8;
    
}
.profile-screen>.profile-copy>.MuiSvgIcon-root:active {
    background-color: white;
}

.copySuccess{
    position: absolute;
    color: green;
 
    padding: 5px;
    border-radius: 20px;
    left: 105%;
    top: -5px;

}

.excel-total{
    display: flex;
    justify-content: center;
    color: #212b36;
    font-size: 20px;
}

.excel-button{
    display: flex;
    justify-content: center;
}
.excel-button>button{
    width: 50%;
    box-sizing: border-box;
    padding: 5px 10px;
    margin-top: 10px;
    outline: none;
    border: 1px solid #999;
    background-color: #6993e9;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    transition: all 0.3s linear 0s;
    margin-bottom: 80px;
}
.sms>form>button,
.sms>button {
    width: 100%;
    box-sizing: border-box;
    padding: 5px 10px;
    margin-top: 10px;
    outline: none;
    border: 1px solid #999;
    background-color: #6993e9;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    transition: all 0.3s linear 0s;
    margin-bottom: 80px;
   
}
.sms>button {
    margin-top: 70px;
}

.sms>button:hover {
    background-color: #003366;
    opacity: 0.7;
}

.sms>button:active {
    box-shadow: none;
}



.sms_portal {
    width: 90%;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 5px;
}
.creditscreen{
 
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    padding-left: 5%;
    padding-right: 5%;
    height: 120vh;
    margin-bottom: 150px;
  
}

.sms{
    width: 50%;
    display: flex;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
    height: fit-content;
    margin-bottom: 200px;
    margin-left: 20px;
    flex-direction: column;

}
.price_title{
    margin-top: 10px;
    color: #003366;
    font-family: Helvetica, sans-serif, Arial;
}
th,td{
    padding: 8px;
    text-align: center !important;
}

.sms>.sms_portal>p,
.sms>form>h2,
.sms>form>h3{
    color: #003366;
    font-family: Helvetica, sans-serif, Arial;
}

.sms>form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}

.sms>form>h2 {
    margin-bottom: 10px;
    margin-top: 0;
}

.sms>form>input {
    width: 100%;
    height: 25px;
    font-size: 20px;
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    outline: none;
    border: none;
    border-bottom: 1px dotted #003366;
    color: #003366;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-family: Helvetica, sans-serif, Arial;
}
.timer{
    margin-top: 20px;
}
 div.profile-copy > div{
display: flex;
align-items: center;
justify-content: center;
}

.admin > h4{
margin-bottom: 10px;
margin-top: 0;
color: #003366;
    font-family: Helvetica, sans-serif, Arial;
}

.creditform,
.manuallycredit{
    display: flex;
    flex-direction: column;
  justify-content: center; 
  margin-top: 10px; 
}

.creditform>form>h2,
.manuallycredit>form>h2 {
    margin-bottom: 10px;
    margin-top: 0;
}
.creditform>form>input,
.manuallycredit>form>input {
    width: 80%;
    height: 25px;
    font-size: 20px;
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    outline: none;
    border: none;
    border-bottom: 1px dotted #003366;
    color: #003366;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-family: Helvetica, sans-serif, Arial;
   
}
.creditform>form>button,
.manuallycredit>form>button{
    width: 80%;
    box-sizing: border-box;
    padding: 5px 10px;
    margin-top: 10px;
    outline: none;
    border: 1px solid #999;
    background-color: #6993e9;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    transition: all 0.3s linear 0s;
    margin-bottom: 15px;

}

.admin>h2{
margin-left: auto;
}
.dashboard-details-screen,
.purchasesmshistory{
    width: 90%;
    overflow-y: auto;
    height: 120vh;
    padding: 5px 5% 5px 5%;
    margin-bottom: 200px;
    font-family: Helvetica, sans-serif, Arial;
    height: 120vh;
    overflow-y: auto;
}
.header-menu{
    margin-top: 10px;
    margin-left: 10px;
    background: lightgrey;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}
.logout-button{
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
   
}
.logout-button>button{
    width: 80%;
    height: 25px;
    font-size: 20px;
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    outline: none;
    border: none;
    color: #003366;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-family: Helvetica, sans-serif, Arial;
    cursor: pointer;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

}
.logout-button>button:hover {
    background-color: lightgrey;
    opacity: 0.7;
}
#root > div > div > div.section > div:nth-child(1) > div.header-menu > div.senderid{
height: 10px;
width: 10px;
margin-top: 10px;
align-self: center;
border-radius: 50%;
}
.phonebook1{
    display: flex;
    flex-direction: column;
    line-height: 3;
    width: 90%;
}

@media (min-width: 1000px){
  
    .toggle_bar{
        display: none;
    }
  
}

.app-form > form > div:nth-child(14) > a > div{
    cursor: pointer;
    color: #7e0556 !important;
    border-bottom: solid 0.1px;
}
.excel-customers,
.excel-numbers,
.excel-report{
    display: flex;
    justify-content: center;
}
.excel-customers>span>button,
.excel-numbers>span>button,
.excel-report>span>button{
    margin: 10px 0;  
    align-self: center;
    box-sizing: border-box;
    padding: 5px 5px;
    outline: none;
    border: 1px solid #999; 
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    transition: all 0.3s linear 0s;
    background-color: green;
    width: 300px;    
}
.sentSms-description{
    font-weight: bold;
    font-size: 15px;
}

#modal-reseller{
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    overflow: auto;
    width: inherit;
    margin: auto;
    margin-top: 8%;
}
#modal-reseller{
    width: 80%;
    height: 70vh;
}

#modal-reseller>form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    border: 0.5px solid lightgrey;
    width: 100%;
    max-width: 1000px;
    background-color: lightgrey;
    margin: auto;
}
#modal-reseller>form>button{
   margin-top: 20px;
    margin-bottom: 20px;
    width: 100px;
    align-self: center;
}

#modal-reseller>form>div>input[type=text],
#modal-reseller>form>div>input[type=password]{
    margin: 0 5%;
    padding-left: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
    border: 1px #003366;
    color: #003366;
    border-radius: 5px;
    font-family: Helvetica, sans-serif, Arial;
    background: #eff4f3;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    width:50%;
}

#modal-reseller>form>div>label,
#modal-reseller>form>div>img,
#modal-reseller>form>div>input[type=radio]{
    margin: 0 5%;
}
#modal-reseller>form>h2{
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 20px;
}
#modal-reseller>form>h4{
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 20px;
}


.reseller-container{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 90%;
    background: azure;
    padding: 20px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
}

.reseller-container>.reseller-button{
 margin-top: 20px;
}

#modal-reseller>form>div{
    margin-left: 20px;
    margin-right: 10px;
}
.phone-book{
    display: flex;
    flex-wrap: wrap;
    margin:-10px;
}
.phone-book-card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    border-radius: 20px;
    height: 220px;
    margin: 10px;
    padding-top: 15px;
}
.phone-book-card__name_container{
    display: flex;
    flex-direction: column;
    margin-top: -70px;
}
.phone-book-card__name{
    font-size: 20px;
    font-weight: bold;
    color: #003366;
}
.phonebook-contacts>.phonebook-contacts-headtitle{
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #003366;
}
.phone-book-card__author,
.phone-book-card__setup-time{
    font-size: 10px;
}
.phone-book-card__author>span{
    font-weight: bold;    
}
.phone-book-card__setup-time>span{
    font-weight: bold;
}
.phone-book-card__open_book{
    cursor: pointer;
    color: #003366;
    margin-bottom: 15px;
}
.contacts-container{
    display: flex;
    flex-wrap: wrap;
    margin:-10px;
}
.contact-wrap{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 280px;
    border-radius: 20px;
    height: 150px;
    margin: 10px;
    padding: 15px;
    background: azure;
}
.contact-wrap>div{
    margin-left: 10px;
    color: #003366;
    font-size: bold;
    font-family: 'Courier New', Courier, monospace;
}
.contact-wrap>div>span{
    font-size: 16px;
    font-style: oblique;
}
.book-icon-containers{
    align-self: end;
}
.contact-icon-containers{
    display: flex;
    justify-content: space-between;
}
.edit-icon{
    cursor: pointer;
    margin-top: 5px;
}
.payment-container{
    display: flex;
    flex-wrap: wrap;
    margin:-10px;
}
.payment-headtitle{
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #003366;
    align-self: start;
}
.payment_wrap_client{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 280px;
    border-radius: 20px;
    height: 290px;
    margin: 10px;
    padding: 15px;
    background: #9cbbf8;
}

.payment_wrap{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 280px;
    border-radius: 20px;
    height: 290px;
    margin: 10px;
    padding: 15px;
    background: cornsilk;
}

.userlist-headtitle{
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #003366;
    align-self: start;
}

.userlist-container{
    display: flex;
    flex-wrap: wrap;
    margin:-10px;
}

.user_client{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 280px;
    border-radius: 20px;
    height: 290px;
    margin: 10px;
    padding: 15px;
    background: cornsilk;

}
.pendig-report{
    margin-top: 10px;
    font-size: 12px;
    color: black;
}
.message-warning{
    font-size: 10px;
}

.normalprice-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.normalprice-container>.button-containers>.delete-btn-price>button{
    color: red;
}
.normalprice-container>.button-containers>.delete-btn-price{
    background: whitesmoke;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75)
}

.normalprice-container>.button-containers>.delete-btn-price>button:hover{
    color: rgb(255, 0, 0);
}

.normalprice-container>.button-containers>.save-btn-price>button{
    color: blue;
}
.normalprice-container>.button-containers>.save-btn-price{
    background: whitesmoke;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    margin-bottom: 50px;
}

.normalprice-container>.button-containers>.save-btn-price>button:hover{
    color: rgb(0, 0, 255);
}


.normalprice-wrap{
    display: flex;
    margin: 10px;
    background: whitesmoke;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    flex-wrap: wrap;
}
.normalprice-wrap>div>input{
    margin: 0 5%;
    padding-left: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
    border: 1px #003366;
    color: #003366;
    border-radius: 5px;
    font-family: Helvetica, sans-serif, Arial;
    background: #eff4f3;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    width:40%; 

}

.normalprice-wrap>.sms-credits>input{
    box-shadow: none;
}


.normalprice-wrap>.price,
.normalprice-wrap>.sms-credits,
.normalprice-wrap>.expiry,
.normalprice-wrap>.rate
{
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    width: 100%;
}


.price-management-container>.add-new-price>button{
    color: darkgreen;
}
.price-management-container>.add-new-price{
    width: 40px;
    background: whitesmoke;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    margin: 15px 
}
