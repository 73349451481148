.sidebar {
    margin: 15px 5px 0px 5px;
    margin-bottom: 10px;
}

.link {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px 5px;
    transition: color 100ms ease-out;
}

.link:hover {
    background-color: rgb(232, 229, 229);
}

.active {
    background-color: rgb(126, 164, 247);
    color: rgb(15, 15, 56);
}

.link>div {
    margin-left: 10px;
}