.login-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: relative;
}
.login-container>.table1{
    margin-top: 30px;
}
.login-container>.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 0.5px solid lightgrey;
    width: 100%;
    max-width: 350px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: lightgrey;
    margin-top: 30px;
}

.message-form {
    position: absolute;
    z-index: 100;
    top: 0px;
}
.message-form>div {
    font-size: 14px;
    margin-top: 20px;
}

.login_portal {
    width: 90%;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: -10px;
}

.login>.login_portal>img {
    object-fit: contain;
    height: 50px;
    margin-right: 20px;
    border-radius: 50px;
}

.login>.login_portal>p,
.login>form>h2,
.login>form>h3,
.login>form>.forgot-password,
.login>form>.login_link {
    color: #003366;
    font-family: Helvetica, sans-serif, Arial;
}
.forgot-password{
    display: flex;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
}
.login_link > a,
.forgot-password > div:nth-child(2){
    cursor: pointer;
    color: #7e0556 !important;
}

.login>form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login>form>h2 {
    margin-bottom: 10px;
    margin-top: 0;
}

.login>form>input {
    width: 100%;
    height: 25px;
    font-size: 20px;
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    outline: none;
    border: none;
    border-bottom: 1px dotted#003366;
    color: #003366;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-family: Helvetica, sans-serif, Arial;
}


.login>form>button {
    width: 100%;
    box-sizing: border-box;
    padding: 5px 0;
    margin-top: 10px;
    outline: none;
    border: 1px solid #999;
    background-color: #6993e9;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    transition: all 0.3s linear 0s;
    margin-bottom: 20px;
    margin-top:30px;
}

.login>form>button:hover {
    background-color: #003366;
    opacity: 0.7;
}

.login>form>button:active {
    box-shadow: none;
}



.login>form>button[disabled] {
    background-color: grey !important;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    overflow: auto;
    width:inherit;
   max-width: 350px;
    margin: auto;
    margin-top: 10%;
}
.modal>form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 0.5px solid lightgrey;
    width: 100%;
    max-width: 350px;
    padding: 20px;
    background-color: lightgrey;
    margin: auto;
    margin-top: 0px;
}
.modal>form>input[type=text],
.modal>form>input[type=password] {
    margin: 0 5%;
    padding-left: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
    border: 1px #003366;
    color: #003366;
    border-radius: 5px;
    font-family: Helvetica, sans-serif, Arial;
    background: #eff4f3;
    width: 100%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.modal>form>button {
    margin: 10px;
}

.modal>form>button:hover {
    background-color: #003366;
    opacity: 0.7;
}
th,td{
    text-align: center !important;
}