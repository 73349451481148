.alert-info {
    color: #2020a0;
    background-color: #e0e0ff;
}

.alert-failed,
.alert-danger {
    color: #a02020;
    background-color: #ffe0e0;
    padding: 10px;
    border-radius: 20px;
}

.alert-successful {
    color: #20a020;
    background-color: #eeffe0;
}